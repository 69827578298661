<template>
  <div>
    <div style="width: 100vw;height: 100vh;display: flex;align-items: center;justify-content: center"  v-hotkey="keymap" v-show="showLogin">
      <el-card style="margin-bottom: 100px;width: 450px" >
        <h3 style="text-align: center">WHATSCHIP</h3>
        <el-form :model="formData" :rules="rules" ref="ruleForm" label-position="top">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="formData.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="formData.password" type="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="login" style="width: 100%">登录</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
      <div v-if="!showLogin" style="margin: 0;background: white">
          <center><h1>404 Not Found</h1></center>
          <hr><center>nginx</center>
      </div>
  </div>
</template>

<script>
import {apiLogin} from '@/request/api'
export default {
  name: "login",
  data () {
    return {
      formData:{
        username:'',
        password:''
      },
      showLogin:true,
      rules: {
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        username: [{ required: true, message: '请输入用户', trigger: 'blur' }]
      }
    }
  },
  computed: {
    keymap () {
      return {
        'shift+a': this.toggle,
      }
    }
  },
  mounted(){
    // this.keyCodeForEvent()
  },
  methods: {
    login() {
      apiLogin(this.formData).then(res => {
        if(res.code == 200){
          localStorage.setItem('token',JSON.stringify(res.data.token))
          localStorage.setItem('userInfo',JSON.stringify(res.data))
          this.$router.replace('/')
        }else{
          this.$message.error(res.message)
        }
      })
    },

    toggle() {
      this.showLogin = true
    },
  }
}
</script>

<style scoped>

</style>
